<!--卡券订单-->
<template>
  <div class="orderList">
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="手机号/卡券名称/卡券id"
          @input="kwChange"
          clearable
          class="mr15"
        />
        <el-select
          v-model="tableParams.paymentType"
          placeholder="全部获得方式"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(getTypeObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.cardStatus"
          placeholder="全部状态"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(cardOrderStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.cardType"
          placeholder="请选择卡券类型"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(cardTypeObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-date-picker
        v-model="dateToDate"
        type="daterange"
        align="center"
        unlink-panels
        range-separator="~"
        start-placeholder="获得时间"
        end-placeholder="获得时间"
        :picker-options="pickerOptions"
        @change="reload"
        clearable
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <div class="search-R">
        <el-button type="primary" @click="onExport()">导出</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="获得时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户信息" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.userName || "-" }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.cardName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="适用范围" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.roomLimit || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="可用日期" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <p v-for="(item, index) in scope.row.cardSuit" :key="index">
            <span>*该卡券{{ item.suitWriting }}，</span>
            <span
              v-if="
                item.suitStartTime == '00:00:00' &&
                item.suitEndTime == '00:00:00'
              "
              >全天可用</span
            >
            <span v-else
              >{{ item.suitStartTime }}-{{ item.suitEndTime }}可用</span
            >
            <span
              v-if="
                item.continuousMaxHour &&
                item.continuousMaxHour != 0 &&
                item.continuousMaxHour != 24
              "
              >{{ item.continuousMaxHour }}小时</span
            >
          </p>
        </template>
      </el-table-column>
      <el-table-column label="获得方式" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.paymentTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券id" prop="memberCardId"></el-table-column>
      <el-table-column label="卡券剩余" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.residue }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期至" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.validDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span
            :style="{ color: cardOrderStatusColor[scope.row.cardStatus] }"
            >{{ scope.row.cardStatusName || "-" }}</span
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { ObjToOpt,formatterDate } from "@/utils/utils";
import {
  cardTypeObj,
  getTypeObj,
  cardOrderStatusObj,
  cardOrderStatusColor,
} from "@/db/objs";


import { getOrderList, getCardOrderExport } from "@/api/card/card.js";
import { create } from "lodash";
export default {
  data() {
    return {
      formatterDate,
      ObjToOpt,
      cardTypeObj,
      getTypeObj,
      cardOrderStatusColor,
      cardOrderStatusObj,
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        kw: "",
        cardType: "",
        paymentType: "",  
        cardStatus: "",
        page: 1,
        size: 10,
        merchantId: 0, //商户ID
        dateFrom: "",
        dateTo: "",
        spId:''
      },
      dateToDate: [], //日期
      selectDate:'',
      pickerOptions:{
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            // const one = 30 * 24 * 3600 * 1000;
            // const minTime = this.selectDate - one;
            // const maxTime = this.selectDate + one;
            // orderList.vue:225 1722528000000 1727712000000
            // 1722441600000 1725120000000
            let d=new Date(this.selectDate)
            const minTime = d.setMonth(d.getMonth() - 1)
            const maxTime = d.setMonth(d.getMonth() + 2)
            console.log(minTime,maxTime)
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      }
    };
  },
  created(){
    this.tableParams.spId=localStorage.getItem('spId')
    console.log(this.tableParams)
  },
  mounted() {
    // this.initDate()
    this.getOrderList(); // 【请求】表格数据
    
  },
  methods: {
    initDate(){
      let start=new Date().getTime()
      let d=new Date(start)
      d.setMonth(d.getMonth() - 1)
      this.tableParams.dateTo=this.formatterDate(start)
      this.tableParams.dateFrom=this.formatterDate(d)
      this.dateToDate=[this.formatterDate(d),this.formatterDate(start)]
    },
    // 【请求】表格数据
    getOrderList() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      } else {
        this.tableParams.dateFrom = "";
        this.tableParams.dateTo = "";
      }
      let data = this.tableParams;
      getOrderList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getOrderList();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getOrderList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
    // 【监听】导出
    onExport() {
      // let data = {
      //   merchantId: 0, //商户ID
      // };
      getCardOrderExport(this.tableParams);
    },
  },
};
</script>
<style lang="scss" scoped>
.orderList {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;

  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}
.search-R {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>